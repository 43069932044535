@media (orientation: portrait) {
    .dashboardContainer{
        background-image: url("./images/container_background.jpg");
        background-size: cover;
        background-position: center;
        overflow: hidden;
        position: absolute;
        height: 93vh;
        width: 100vw;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
    }
    
    .dashboardCardContainers{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 93vh;
    }
    
    .dashboardCard{
        caret-color: rgba(0,0,0,0);
        border: 1px solid rgba(31, 127, 196, 0.819);
        color: white;
        height: 10rem;
        min-width: 10rem;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(31, 127, 196, 0.819);
        margin: 10px;
        border-radius: 10%;
        box-shadow: 1px 3px 20px 0px rgba(110, 0, 200, 0.739);
    }
    
    .iconSize{
        font-size: 5rem;
    }
    .dashboardItemTitle{
        text-align: center;
        ;
    }
}
@media (orientation: landscape) {
    .dashboardContainer{
        background-image: url("./images/container_background.jpg");
        background-size: cover;
        background-position: center;
        overflow: hidden;
        position: absolute;
        height: 90vh;
        width: 100vw;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
    }
    
    .dashboardCardContainers{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 93vh;
    }
    
    .dashboardCard{
        caret-color: rgba(0,0,0,0);
        border: 1px solid rgba(31, 127, 196, 0.819);
        color: white;
        height: 10rem;
        min-width: 10rem;
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(31, 127, 196, 0.819);
        margin: 10px;
        border-radius: 10%;
        box-shadow: 1px 3px 20px 0px rgba(110, 0, 200, 0.739);
    }
    
    .iconSize{
        font-size: 5rem;
    }
    .dashboardItemTitle{
        text-align: center;
        ;
    }

}