
#gameContainer{
    caret-color: rgba(0,0,0,0);
    height: 100vh;
    width: 100vw;
    background: white;
}

.drawGame{
    caret-color: rgba(0,0,0,0);
    height: 93vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.canvasOption{
    caret-color: rgba(0,0,0,0);
    width: 100vw;
    margin-top: 10px;
    align-items: baseline;
    display: flex;
    justify-content: space-around;
}