.loginForm{
    width: fit-content;
    box-shadow: 3px 2px 10px 3px;
    border-radius: 75px;
    padding: 25px;
    background-image: url("./images/login_background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 10%;
}
.formContainer{
    
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
   
}
.hompageContainer{
    background-image: url("./images/container_background.jpg");
    background-size: cover;
    background-position: center;
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    // left: 50%;
    // transform: translateX(-50%);
}
.inputPosition{
    color: linen;
    width: 75%;
    margin-left: 20px;
}
.LabelText{
    color: rgb(12, 7, 2);
    font-weight: 700;
}
.LabelText2{
    font-weight: 900;
    color: black;
}