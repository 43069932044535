@media (orientation: landscape) {
    .menuCustomClass{
        caret-color: rgba(0,0,0,0);
    }
    
    .header{
        height: 10vh;
        caret-color: rgba(0,0,0,0);
    }
}


// 10vh
@media (orientation: portrait) {
    .menuCustomClass{
        caret-color: rgba(0,0,0,0);
    }
    
    .header{
        height: 7vh;
        caret-color: rgba(0,0,0,0);
    }
}